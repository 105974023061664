import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faUser, faSitemap, faMapSigns, faFileInvoice, faVectorSquare, faHandPointUp , faBalanceScale, faPaintBrush, faDesktop } from '@fortawesome/free-solid-svg-icons';
import ScrollSpy from "react-scrollspy-navigation";
import { StaticImage } from "gatsby-plugin-image";
import SpyButton from "../components/spy-button";
import Layout from "../components/layout";
import Seo from "../components/seo"
import Quote from "../components/quote";
import CTA from "../components/cta";
import patternHex from "../images/backrounds/pattern-hex-white.jpg";

const StudiosDesignProcess = ({location}) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="Studios Process"
        description="Read about our strategic UI/UX and brand development process that gives you the opportunity to put your brand on the map."
      />
      <nav className="scroll-spy-nav">
        <ul className="nav nav-pills flex-column">
          <ScrollSpy>
            <SpyButton href="#Business" ref={React.createRef()}>
              <span className="nav-icon">
                <FontAwesomeIcon icon={faFileAlt} />
              </span>
              Document
            </SpyButton>
            <SpyButton href="#UserPersonas" ref={React.createRef()}>
              <span className="nav-icon">
                <FontAwesomeIcon icon={faUser} />
              </span>
              Personas
            </SpyButton>
            <SpyButton href="#SiteMaps" ref={React.createRef()}>
              <span className="nav-icon">
                <FontAwesomeIcon icon={faSitemap} />
              </span>
              Sitemap
            </SpyButton>
            <SpyButton href="#UserFlow" ref={React.createRef()}>
              <span className="nav-icon">
                <FontAwesomeIcon icon={faMapSigns} />
              </span>
              Journey
            </SpyButton>
            <SpyButton href="#WorksheetTemplates" ref={React.createRef()}>
              <span className="nav-icon">
                <FontAwesomeIcon icon={faFileInvoice} />
              </span>
              Worksheet
            </SpyButton>
            <SpyButton href="#Wireframes" ref={React.createRef()}>
              <span className="nav-icon">
                <FontAwesomeIcon icon={faVectorSquare} />
              </span>
              Wireframe
            </SpyButton>
            <SpyButton href="#Prototypes" ref={React.createRef()}>
              <span className="nav-icon">
                <FontAwesomeIcon icon={faHandPointUp} />
              </span>
              Prototype
            </SpyButton>
            <SpyButton href="#UserTesting" ref={React.createRef()}>
              <span className="nav-icon">
                <FontAwesomeIcon icon={faBalanceScale} />
              </span>
              Testing
            </SpyButton>
            <SpyButton href="#BrandSystem" ref={React.createRef()}>
              <span className="nav-icon">
                <FontAwesomeIcon icon={faPaintBrush} />
              </span>
              Brand
            </SpyButton>
            <SpyButton href="#Mockups" ref={React.createRef()}>
              <span className="nav-icon">
                <FontAwesomeIcon icon={faDesktop} />
              </span>
              Mockup
            </SpyButton>
          </ScrollSpy>
        </ul>
      </nav>
      <section>
        <div
          className="hero"
          style={{
            backgroundImage: `url(${patternHex})`,
          }}
        >
          <div className="hero-title bg-primary-transparent">
            <h1 className="text-white">729 STUDIOS PROCESS</h1>
            <p className="text-white">
              We at 729 Solutions offer a number of strategic UI/UX and brand
              development opportunities to put your brand on the map. Whether
              you’re a new organization or a decades-old local business looking
              to rebrand, our team can give you an image that sells.
            </p>
            <CTA
              href="/contact-us/"
              text="LET US RUN YOUR DISCOVERY PROCESS"
              className="btn btn-secondary-light"
            />
          </div>
        </div>
      </section>

      <section id="Business">
        <div className="container py-6">
          <div className="row">
            <div className="col-md-6 order-1 order-md-0">
              <h2>Business Requirements Documents</h2>
              <p>
                Business Requirements Documents are a wide-scale inspection and
                analysis of your current digital needs and a report on the
                methodology by which 729 Solutions can help achieve them.
              </p>
              <p className="mb-0">
                <strong>
                  When constructing a Business Requirement Document, 729 will:
                </strong>
              </p>
              <ul className="text-primary">
                <li>
                  <span className="text-dark">
                  Determine the scope and purpose of the discovery phase.
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                  Interview your team and catalog weaknesses (or “pain points”)
                  in your digital presence and web functionality to assure no
                  sticking point goes unaddressed.
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                  Document elements of your website that work as intended and
                  required to assure those services remain unchanged.
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                  Perform research on competitors and comparable products and
                  services. We also will analyze the customer base of those
                  competitors and any technology used to achieve their goals.
                  This will help you stay ahead of the pack.
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                  Analyze available industry data and analytics to assure each
                  technical decision is backed up with a tried and true
                  methodology.
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                  Note any other considerations and uncover and predict any
                  roadblocks or complications that may arise while solving your
                  digital needs.
                  </span>
                </li>
              </ul>
            </div>
            <div className="col-md-6 order-0 order-md-1 pb-5 text-center">
              <StaticImage
                src="../images/backrounds/bureaucracy.jpg"
                alt="BUREAUCRACY"
              />
            </div>
          </div>
        </div>
      </section>

      <section
        id="UserPersonas"
        className="bg-light"
      >
        <div className="container py-6">
          <div className="row mb-4">
            <div className="col">
              <h2>User Personas</h2>
              <p>User personas are detailed explanations and data points about the representative categories and social groups of users<br/> visiting your website. (Examples include local customer, industry personnel, press, etc.).</p>
              <p><strong>In order to learn which personas make up your audience, 729 will:</strong></p>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-lg-3">
            <div className="col">
                <div className="card custom-cards card-border h-100">
                  <div className="card-body">
                    <svg 
                      className="mb-3"
                      width={275}
                      height={90}
                      viewBox="9 48 126 48"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g className="text-primary">
                        <circle
                          cx="116.06"
                          cy="60.59"
                          fill="none"
                          r="9.9"
                          stroke="currentColor"
                          strokeWidth="3"
                        />
                        <path
                          d="M98.31,94.64s1.74-20,18.65-19.8,16.73,19.8,16.73,19.8"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="3"
                        />
                        <circle
                          cx="28.05"
                          cy="60.59"
                          fill="none"
                          r="9.9"
                          stroke="currentColor"
                          strokeWidth="3"
                        />
                        <path
                          d="M10.31,94.64s1.74-20,18.64-19.8,16.74,19.8,16.74,19.8"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="3"
                        />
                      </g>
                      <g className="text-primary-light">
                        <path
                          d="M57.63,52.36v5h-5v-5h5m3-3h-11v11h11v-11Z"
                          fill="currentColor"
                        />
                        <path
                          d="M57.63,74.36v5h-5v-5h5m3-3h-11v11h11v-11Z"
                          fill="currentColor"
                        />
                        <path
                          d="M73,52.36v5H68v-5h5m3-3H65v11H76v-11Z"
                          fill="currentColor"
                        />
                        <path
                          d="M73,67.76v5H68v-5h5m3-3H65v11H76v-11Z"
                          fill="currentColor"
                        />
                        <path
                          d="M88.43,52.36v5h-5v-5h5m3-3h-11v11h11v-11Z"
                          fill="currentColor"
                        />
                        <path
                          d="M88.43,67.76v5h-5v-5h5m3-3h-11v11h11v-11Z"
                          fill="currentColor"
                        />
                        <path
                          d="M88.43,83.16v5h-5v-5h5m3-3h-11v11h11v-11Z"
                          fill="currentColor"
                        />
                      </g>
                    </svg>
                    <p className="m-0">
                      Collect data about your audience through a number of different means including interviews, data analytics and market research.
                    </p>
                  </div>
                </div>
            </div>
            <div className="col">
                <div className="card custom-cards card-border h-100">
                  <div className="card-body">
                    <svg
                      className="mb-3"
                      width={275}
                      height={120}
                      viewBox="25 20 90 120"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g className="text-primary-light">
                        <path
                          d="M26.81,32.66l5.81,5.8L50,21.05"
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="square"
                          strokeMiterlimit="10"
                          strokeWidth="3"
                        />
                        <path
                          d="M26.81,73.3l5.81,5.8L50,61.69"
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="square"
                          strokeMiterlimit="10"
                          strokeWidth="3"
                        />
                      </g>
                      <g className="text-primary">
                        <circle
                          cx="36.02"
                          cy="114.36"
                          fill="none"
                          r="8.59"
                          stroke="currentColor"
                          strokeLinecap="square"
                          strokeMiterlimit="10"
                          strokeWidth="3"
                        />
                        <line
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="square"
                          strokeMiterlimit="10"
                          strokeWidth="3"
                          x1="69.44"
                          x2="117.19"
                          y1="33.19"
                          y2="33.19"
                        />
                        <line
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="square"
                          strokeMiterlimit="10"
                          strokeWidth="3"
                          x1="69.44"
                          x2="117.19"
                          y1="73.3"
                          y2="73.3"
                        />
                        <line
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="square"
                          strokeMiterlimit="10"
                          strokeWidth="3"
                          x1="69.44"
                          x2="117.19"
                          y1="113.41"
                          y2="113.41"
                        />
                      </g>
                    </svg>
                    <p className="m-0">
                      Define the needs, goals, selling-points and expectations of each persona category.
                    </p>
                  </div>
                </div>
            </div>
            <div className="col">
                <div className="card custom-cards card-border h-100">
                  <div className="card-body">
                    <svg
                      className="mb-3"
                      height={120}
                      viewBox="21 21 110 110"
                      width={115}
                      xmlns="http://www.w3.org/2000/svg"
                    >

                    <g className="text-primary-light">
                        <line
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="square"
                          strokeMiterlimit="10"
                          strokeWidth="3"
                          x1="30.5"
                          x2="57.5"
                          y1="24"
                          y2="51"
                        />
                        <line
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="square"
                          strokeMiterlimit="10"
                          strokeWidth="3"
                          x1="30.5"
                          x2="57.5"
                          y1="51"
                          y2="24"
                        />

                        <line
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="square"
                          strokeMiterlimit="10"
                          strokeWidth="3"
                          x1="93.5"
                          x2="120.5"
                          y1="86"
                          y2="114"
                        />
                        <line
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="square"
                          strokeMiterlimit="10"
                          strokeWidth="3"
                          x1="93.5"
                          x2="120.5"
                          y1="114"
                          y2="86"
                        />
                      </g>
                      <g className="text-primary">
                        <line
                          fill="none"
                          stroke="currentColor"
                          strokeMiterlimit="10"
                          strokeWidth="3"
                          x1="68.5"
                          x2="120.5"
                          y1="75"
                          y2="24"
                        />
                        <ellipse
                          cx="43.5"
                          cy="100.5"
                          fill="none"
                          rx="20"
                          ry="20.5"
                          stroke="currentColor"
                          strokeLinecap="square"
                          strokeMiterlimit="10"
                          strokeWidth="3"
                        />
                        <path
                          d="M89.5,23h31V54"
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="square"
                          strokeMiterlimit="10"
                          strokeWidth="3"
                        />
                      </g>
                    </svg>
                    <p className="m-0">
                      Create a more efficient strategy to cater to each persona group.
                    </p>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </section>
      <section id="SiteMaps">
        <div className="container py-6">
          <div className="row">
            <div className="col-md-6 order-1 order-md-0">
              <h2>Sitemaps</h2>
              <p>
                729 will review or redesign your site map to efficiently plan the structure of your website, software or app and assure all pages from user flow are accounted for. A fully optimized site map will assist with accessibility and industry standards and has SEO benefits such as higher placement in Google results.
              </p>
            </div>
            <div className="col-md-6 order-0 order-md-1 pb-5 text-center">
              <StaticImage
                src="../images/backrounds/sitemap.png"
                alt="Sitemap"
              />
            </div>
          </div>
        </div>
      </section>
      <section id="UserFlow" className="bg-light">
        <div className="container py-6">
          <div className="row">
            <div className="col-md-6 order-1 order-md-0">
              <h2>User Flow Maps</h2>
              <p>
                729 will map and optimize the user’s journey through your site (called a “user flow”) based on user personas and their specific goals.
              </p>
              <p>
                After documenting the current step-by-step processes users go through to complete a task, we consider different scenarios and possible solutions to streamline the site and remove unnecessary steps or complications.              
              </p>
            </div>
            <div className="col-md-6 order-0 order-md-1 pb-5 text-center">
              <StaticImage
                src="../images/backrounds/userflow.png"
                alt="UserFlow"
              />
            </div>
          </div>
        </div>
      </section>
      <section id="WorksheetTemplates">
        <div className="container py-6">
          <div className="row">
            <div className="col-md-6 order-1 order-md-0">
              <h2>Worksheet Templates</h2>
              <p>
                Worksheet Templates catalog every page of your website and include a page-by-page analysis of current content and functionality. Each template includes suggestions for optimization and content improvements.
              </p>
              <p className="mb-0">
                <strong>
                  Worksheet Templates will:
                </strong>
              </p>
              <ul className="text-primary">
                <li>
                  <span className="text-dark">
                    Help you determine what content should be on what page.
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    Help designers offer user interface (UI) and user experience (UX) improvements on a page-by-page basis.
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    Help developers clearly understand and improve the purpose
                  </span>
                </li>
              </ul>
            </div>
            <div className="col-md-6 order-0 order-md-1 pb-5 text-center">
              <StaticImage
                src="../images/backrounds/worksheets.png"
                alt="Worksheet"
              />
            </div>
          </div>
        </div>
      </section>
      <section id="Wireframes" className="bg-light">
        <div className="container py-6">
          <div className="row">
            <div className="col-md-6 order-1 order-md-0">
              <h2>Desktop, Tablet And Mobile Wireframes</h2>
              <p>
                Wireframes are early design demonstrations with low fidelity and no functionality. Instead, these demos help clients to quickly and easily ensure the design direction aligns with their goals and values.
              </p>
              <p>
                Additionally, they can be used later in the process to build low-fidelity prototypes for user testing.
              </p>
            </div>
            <div className="col-md-6 order-0 order-md-1 pb-5 text-center">
              <StaticImage
                src="../images/backrounds/userflow.png"
                alt="Wireframes"
              />
            </div>
          </div>
        </div>
      </section>
      <section id="Prototypes">
        <div className="container py-6">
          <div className="row">
            <div className="col-md-6 order-1 order-md-0">
              <h2>Desktop, Tablet And Mobile Prototypes</h2>
              <p>
                Prototypes can be created during many stages of the design process with both high-fidelity mockups and low fidelity wireframe demonstrations. These early demos will show you how your website will appear on computers, cell phones and tablets and offer some basic functionality including menu buttons.
              </p>
              <p className="mb-0">
                <strong>
                  Desktop and mobile prototypes can:
                </strong>
              </p>
              <ul className="text-primary">
                <li>
                  <span className="text-dark">
                    Help visualize the final intent of product pages.
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    Allow user testing to ensure the product is moving in the right direction.
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    Be presented at keynote speeches and funding pitches for demonstration purposes, to provide a reliable and lifelike experience.
                  </span>
                </li>
              </ul>
            </div>
            <div className="col-md-6 order-0 order-md-1 pb-5 text-center">
              <StaticImage
                src="../images/backrounds/prototype.png"
                alt="Prototype"
              />
            </div>
          </div>
        </div>
      </section>
      <section id="UserTesting" className="bg-light">
        <div className="container py-6">
          <div className="row">
            <div className="col">
              <h2>User Testing, User Interviews And Result Reports</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 pb-5 text-center">
              <StaticImage
                src="../images/backrounds/user-testing.png"
                alt="User Testing"
              />
            </div>
            <div className="col-md-9">
              <p>
                During the user testing process, 729 will test your site with real-life users from your site’s user persona categories. These users will be presented with your site and tested using scripts built to identify key interactions users take when using your product or service. User testing and interviews can be arranged in person on-site or virtually.
              </p>
              <p>
                As the users complete these actions, user interface (UI) and user experience (UX) experts will observe and complete a report with recommendations to improve the site’s functionality, accessibility and ease of use.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="BrandSystem">
        <div className="container py-6">
          <div className="row mb-4">
            <div className="col">
              <h2>Brand System And Style Guide Creation</h2>
              <p>
                729’s designers and consultants will use brainstorming sessions, workshops, mood boards, sketches, and more to help you develop a brand system based on your unique mission, vision, and goals. This system will contain a collection of unified elements such as logos, fonts and color schemes that help solidify an easily identifiable look and feel for your brand.
              </p>
              <p>
                <strong>In-depth brand systems also can improve cross-platform consistency by including a front end asset library, which contains resources such as:</strong>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 pb-5 text-center">
              <StaticImage
                src="../images/backrounds/brand-creation.png"
                alt="Brand Creation"
              />
            </div>
            <div className="col-md-3">
              <ul className="text-primary font-weight-bold">
                <li><span className="text-dark">Logos</span></li>
                <li><span className="text-dark">Typography</span></li>
                <li><span className="text-dark">Icons</span></li>
                <li><span className="text-dark">Colors</span></li>
              </ul>
            </div>
            <div className="col-md-3">
              <ul className="text-primary font-weight-bold">
                <li><span className="text-dark">Backgrounds</span></li>
                <li><span className="text-dark">Patterns</span></li>
                <li><span className="text-dark">Webdesign Components</span></li>
                <li><span className="text-dark">Business Cards</span></li>
              </ul>
            </div>
            <div className="col-md-3">
              <ul className="text-primary font-weight-bold">
                <li><span className="text-dark">Letterheads</span></li>
                <li><span className="text-dark">Proposal Templates</span></li>
                <li><span className="text-dark">Presentation Templates</span></li>
              </ul>
            </div>
          </div>
            <div className="col">
              <p>
                All of these elements will assure your brand identity is consistent and easily recognizable both on and off the web.
              </p>
            </div>
        </div>
      </section>
      <section id="Mockups" className="bg-light">
        <div className="container py-6">
          <div className="row">
            <div className="col-md-6 order-1 order-md-0">
              <h2>Mockups</h2>
              <p>
                Mockups are high-fidelity images that allow you to see what your website, software or app will look like upon completion. Every mockup includes all of your brand imagery, colors, fonts and other brand-specific components.
              </p>
              <p>
                Using a mockup to reflect the final product allows for flexibility in the final steps of the design process so you can request last-minute changes.
              </p>
            </div>
            <div className="col-md-6 order-0 order-md-1 pb-5 text-center">
              <StaticImage
                src="../images/backrounds/mockups.png"
                alt="Mockups"
              />
            </div>
          </div>
        </div>
      </section>
      <section id="Mockups" className="bg-dark">
        <div className="container py-6">
          <div className="row">
            <div className="col-md-6">
              <Quote
                  dark
                  quote="We started working with 729 at the earliest stages of Bundle and the team was integral in our Minimum Viable Product release. The design team did an awesome job defining granular user personas and created the foundation for the look and feel of our brand."
                  name="Eric Major"
                  company="Bundle Loans"/>
            </div> 
            <div className="col-md-6">
              <Quote
                  dark
                  quote="729 took the time to understand more than just our UI/UX concerns - they explored our business goals as well. This has allowed us to make decisions that consider the larger, long term vision, in addition to solving the immediate needs we brought to the table."
                  name="Kate Benay"
                  company="Director of Marketing, Sundance"/>
            </div> 
          </div>
        </div>
      </section>
      <section className="bg-light">
        <div className="container pb-6">
          <div className="row">
            <div className="col">
              <div className="text-center p-6">
                <h2>Start Your Discovery Today</h2>
                <p>Whether you’re elbow-deep in your project and need help finding clarity, or you’re just getting started and are not sure what steps to take next – we are here to help. We can apply our process to your project from start to finish – or pull out and apply only the steps you need. Reach out today and ask us for a free UI/UX consultation!</p>
                <CTA href="/contact-us/" text="LET'S TALK" className="btn btn-primary"/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default StudiosDesignProcess;
